@media (max-width: 1440px) {
	html {
		font-size: 15px;
	}

	.page-wrapper .k-drawer-container {
		.logo-box {
			width: 100%;

			img {
				width: 100%;
			}
		}
	}
	.add-product-module .panel-link-btn.completed:after {
		left: 1.7rem;
		top: 1.5rem;
		width: 0.4rem;
		height: 0.65rem;
		border-width: 0 0.15rem 0.15rem 0;
	}
	.add-product-module .left-box .k-panelbar .k-panelbar-item .k-link .panel-name.completed:after {
		left: 0.45rem;
		top: 0.22rem;
		width: 0.35rem;
		height: 0.65rem;
		border-width: 0 0.15rem 0.15rem 0;
	}
	.no-data {
		.no-data-body {
			max-width: 30.875rem;
			max-height: 30.875rem;
			.no-data-inner {
				max-width: 30.875rem;
				max-height: 30.875rem;
			}
			&:after {
				max-width: 30.875rem;
				max-height: 30.875rem;
			}
		}
	}
	.page-wrapper
		.k-drawer-container
		.k-widget.k-drawer
		.k-drawer-wrapper
		.k-drawer-items
		.k-drawer-item
		.dropend
		.dropdown-menu.show
		.dropdown-item:nth-child(2) {
		&:before,
		&:after {
			top: 0rem;
		}
	}
	.add-product-module
		.page-content-body
		.row
		.right-column
		.product-form-wrapper
		.section-wrapper
		.no-data
		.no-data-body {
		max-width: 20.875rem;
		max-height: 20.875rem;
		.no-data-inner {
			max-width: 20.875rem;
			max-height: 20.875rem;
			img {
				max-width: 13rem;
			}
		}
	}
	.inline-list-card li .date-box,
	.inline-list-card li .location-box {
		min-width: 10rem;
	}
	.inline-list-card.box-card-type-3 li {
		max-width: calc(33.333333% - 1.15rem);
	}
	.header-popover {
		max-height: 40rem;
	}
	
	.default-kendo-grid, .default-kendo-grid.audit-report-grid {
		min-height: 12rem !important;
		&.page-scroll-no-data{
			max-height: 15rem !important;
			min-height: 12rem;
			.k-grid-header{
				padding-right: 0 !important;
			}
			.k-grid-container{
				height: 100% !important;
				max-height: 12rem !important;
				*{
					height: 100% !important;
				}
				
				.k-grid-content{
					height: 6rem !important;
					max-height: 10rem !important;
					tr.k-grid-norecords td{
						height: 100% !important;
						max-height: 10rem !important;
					}
				}
	
			}
		}
	}
}

@media (max-width: 1280px) {
	html {
		font-size: 14px;
	}

	.no-data {
		.no-data-body {
			max-width: 24.875rem;
			max-height: 24.875rem;
			.no-data-inner {
				max-width: 24.875rem;
				max-height: 24.875rem;
				img {
					width: 15rem;
				}
			}
			&:after {
				max-width: 24.875rem;
				max-height: 24.875rem;
			}
		}
	}

	.page-wrapper
		.k-drawer-container
		.k-widget.k-drawer
		.k-drawer-wrapper
		.k-drawer-items
		.k-drawer-item {
			margin: 0.25rem 0;	/*--old style--*/
		/*--new style - margin: 0.75rem 0;*/
		.dropend .dropdown-menu.show .dropdown-item:nth-child(2) {
			&:before,
			&:after {
				top: 0rem;
			}
		}
	}
	.k-pager-md .k-pager-numbers-wrap .k-button {
		min-width: unset !important;
	}
	[type='radio'] + label .check-outer .check-inner {
		width: 8px;
		height: 8px;
		min-width: 8px;
	}
	.header-popover {
		max-height: 35rem;
	}
	.page-content.add-product-group-module.page-scroll{
		overflow-y: auto;
		max-height: 100%;
		height: auto !important;
		scrollbar-width: thin !important;
		scrollbar-color: var(--scrollbar-thumb-2) var(--scrollbar-track-2) !important;
		&::-webkit-scrollbar {
			width: 0.75rem;
			height: 0.75rem;
			border-radius: 0rem;
		}
		
		&::-webkit-scrollbar-track {
			background-color: var(--scrollbar-track-2);
		}
		
		&::-webkit-scrollbar-thumb {
			background-color: var(--scrollbar-thumb-2);
			border-radius: 0rem;
			&:hover {
				background-color: var(--scrollbar-thumb-hover-2);
			}
		}
		.weblink-report-grid.end-user-report-grid {
			height: auto !important;
			min-height: unset !important;
			
		}
		.default-kendo-grid.weblink-report-grid {
			min-height: 5rem !important;
			height: auto !important;
		}
		.k-pager{
			margin-top: 0.875rem !important;
		}
		.product-order-details-container{
			min-height: calc(100vh - 10.75rem) !important;
			padding-bottom: 6.5rem;
			height: auto !important;
		}
		.page-content-body {
			min-height: calc(100vh - 9.35rem);
			height: auto !important;
		}
		.default-kendo-grid.audit-report-grid {
			height: 100% !important;
			max-height: 27rem;
			
			
		}
	}
	.default-kendo-grid, .default-kendo-grid.audit-report-grid {
		min-height: 10rem !important;
		&.page-scroll-no-data{
			max-height: 12rem !important;
			min-height: 10rem;
			.k-grid-header{
				padding-right: 0 !important;
			}
			.k-grid-container{
				height: 100% !important;
				max-height: 10rem !important;
				*{
					height: 100% !important;
				}
				
				.k-grid-content{
					height: 4rem !important;
					max-height: 8rem !important;
					tr.k-grid-norecords td{
						height: 100% !important;
						max-height: 8rem !important;
					}
				}
	
			}
		}
	}
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	html {
		font-size: 13px;
	}
	.k-animation-container .custom-dropdown.profile-dropdown {
		right: 4.3rem;
	}
	.k-animation-container .custom-dropdown.profile-dropdown.header-no-logo {
		right: 0.3rem;
	}

	.k-animation-container .custom-dropdown.profile-dropdown .k-menu-group:after,
	.k-animation-container .custom-dropdown.profile-dropdown .k-menu-group:before {
		right: 1.65rem;
	}
	.k-animation-container .custom-dropdown.profile-dropdown.header-no-logo .k-menu-group:after,
	.k-animation-container .custom-dropdown.profile-dropdown.header-no-logo .k-menu-group:before {
		right: 1rem;
	}
	.language-switcher-wrapper {
		left: 7.65rem;
	}

	.page-wrapper {
		.sidebar-toggle-btn {
			display: block;
			position: fixed;
			top: 1.75rem;
			left: 5.75rem;
			z-index: 112;
			padding: 0;
			border: 0 !important;
			background-color: transparent !important;
			outline: none !important;
			box-shadow: none !important;

			&:after,
			&:before {
				display: none !important;
			}
		}
	}
	.main-header {
		left: 0;
		width: 100%;
		padding: 0.75rem 0.875rem;
		padding-inline: 0.875rem !important;
		gap: 0 !important;
	}
	.page-wrapper {
		.k-drawer-container {
			.k-widget.k-drawer {
				width: 21rem;
				left: 0;
				.k-drawer-wrapper {
					padding-right: 0.25rem;
					.k-drawer-items {
						height: calc(100% - 7.5rem);
						overflow-y: auto;
						margin-top: 4rem !important;
					}
				}

				.k-drawer-wrapper .k-drawer-items .k-drawer-item {
					margin: 0.25rem 0;
					flex-direction: column;
					align-items: flex-start;
					position: relative;
					&:first-child {
						position: fixed;
						top: 1rem;
						background-color: var(--theme-light-color) !important;
						z-index: 99;
						width: 100%;
						max-width: 17rem;
						height: 4rem;
					}
					&.k-selected .menu-icon {
						color: var(--theme-color);
					}
					&:hover {
						.menu-icon {
							color: var(--menu-icon);
						}

						&.k-selected .menu-icon {
							color: var(--theme-color);
						}
					}
					&:not(:first-child) {
						height: auto;
					}

					.item-descr-wrap {
						opacity: 1;
						color: var(--secondary-color);
						width: auto;
						display: inline-flex;
						left: 0;
						background-color: transparent !important;
						padding-left: 4rem;
						&:hover {
							color: var(--theme-color) !important;
						}
						&.dropdown-active {
							color: var(--theme-color);
						}
					}
					.dropend {
						position: relative;
						width: 100%;
						.dropdown-toggle {
							right: 1rem;
							&.show {
								color: var(--secondary-color) !important;
								background-color: transparent !important ;

								&:before {
									display: none;
								}
							}
						}
						.dropdown-menu {
							background-color: transparent !important ;
							left: 2.9rem !important;
							top: 0 !important;
							position: relative !important;
							width: 14rem;
							overflow: hidden;
							.dropdown-item {
								color: var(--secondary-color) !important;
								background-color: transparent !important ;
								height: 3.25rem;
								&:hover {
									background-color: transparent !important ;
								}
							}
						}
					}

					&.k-selected {
						height: auto;
						.item-descr-wrap {
							color: var(--secondary-color) !important;
							background-color: transparent !important ;
							&.dropdown-active {
								color: var(--theme-color) !important;
							}
						}
						.dropdown-menu {
							position: relative !important;
							transform: none !important;
							color: var(--secondary-color) !important;
							background-color: transparent !important ;
							.dropdown-item {
								padding: 0.5rem 1.1875rem 0.5rem 1.1875rem;
								color: var(--secondary-color) !important;
								background-color: transparent !important ;
							}
						}
					}
				}
				.dropend .dropdown-menu:before {
					display: none;
				}
				.dropend .dropdown-menu.show .dropdown-item:nth-child(1):before {
					-moz-transform: translateX(-0.45rem) translateY(0);
					-webkit-transform: translateX(-0.45rem) translateY(0);
					-o-transform: translateX(-0.45rem) translateY(0);
					-ms-transform: translateX(-0.45rem) translateY(0);
					transform: translateX(-0.45rem) translateY(0);
					display: none;
				}
				.dropend .dropdown-menu.show .dropdown-item:nth-child(1):after {
					display: none;
				}
				.k-drawer-wrapper .k-drawer-items .k-drawer-item {
					.dropend.no-first-menu .dropdown-menu {
						top: -2.8rem !important;
						display: block !important;
						height: 3.25rem;
					}
				}
			}

			&.k-drawer-expanded {
				.k-widget.k-drawer {
					width: 16.35rem;
					left: -16.35rem;

					.k-drawer-wrapper .k-drawer-items .k-drawer-item {
						.item-descr-wrap {
							opacity: 0;
						}
					}
				}
			}

			.logo-box {
				position: fixed;
				left: 0.625rem;
				width: 4.0625rem;
			}

			.k-drawer-content {
				padding-left: 0;
				.page-content {
					.page-header,
					.page-content-body {
						padding-left: 1rem;
						padding-right: 1rem;
					}
				}
			}
		}
	}
	.page-wrapper
		.k-drawer-container
		.k-widget.k-drawer
		.k-drawer-wrapper
		.k-drawer-items
		.k-drawer-item:hover {
		.dropend.no-first-menu {
			.dropdown-menu.show,
			.dropdown-menu {
				height: auto !important;
			}
		}
	}
	.page-wrapper
		.k-drawer-container
		.k-widget.k-drawer
		.k-drawer-wrapper
		.k-drawer-items
		.k-drawer-item
		.dropend.subMenu.show
		.dropdown-toggle {
		color: var(--secondary-color) !important;
		background-color: transparent !important ;
	}
	.default-form-style {
		.input-type-number {
			input[type='number'] {
				&::-webkit-inner-spin-button,
				&::-webkit-outer-spin-button {
					opacity: 0;
				}
			}

			&:before {
				display: none;
			}
		}
	}
	.add-product-module .left-box .k-panelbar .k-panelbar-item .k-link .panel-name.completed:after {
		left: 0.42rem;
		top: 0.15rem;
		width: 0.45rem;
		height: 0.75rem;
		border-width: 0 0.2rem 0.2rem 0;
	}
	.add-product-module .panel-link-btn.completed:after {
		left: 1.7rem;
		top: 1.45rem;
		width: 0.45rem;
		height: 0.75rem;
		border-width: 0 0.2rem 0.2rem 0;
	}
	.add-product-module
		.page-content-body
		.row
		.right-column
		.product-form-wrapper
		.section-wrapper
		.no-data
		.no-data-body {
		&:after {
			display: none;
		}
	}
	.acg-version {
		bottom: 1.5rem;
	}
	.k-drawer-expanded {
		.acg-version {
			left: -6rem;
		}
	}
	.dashboard-body {
		.dsb-left-wrapper {
			flex-direction: column;
			.dsb-left {
				flex-direction: column;
				width: 100%;
			}
			.dsb-right {
				flex-direction: row;
				width: 100%;
				.dashboard-container {
					width: 50%;
				}
			}
		}
	}

	.header-popover {
		max-height: 35rem;
		max-width: 31.4375rem;
		.k-popover-callout.k-callout-n {
			left: 82%;
		}
	}
	.grid-custom-container .grid-custom-container-inner.with-toolbar .k-toolbar{
		right: 2.25rem;
		left: 2.25rem;
	}
	.page-content.add-product-group-module.page-scroll{
		overflow-y: auto;
		max-height: 100%;
		height: auto !important;
		scrollbar-width: thin !important;
		scrollbar-color: var(--scrollbar-thumb-2) var(--scrollbar-track-2) !important;
		&::-webkit-scrollbar {
			width: 0.75rem;
			height: 0.75rem;
			border-radius: 0rem;
		}
		
		&::-webkit-scrollbar-track {
			background-color: var(--scrollbar-track-2);
		}
		
		&::-webkit-scrollbar-thumb {
			background-color: var(--scrollbar-thumb-2);
			border-radius: 0rem;
			&:hover {
				background-color: var(--scrollbar-thumb-hover-2);
			}
		}
		.weblink-report-grid.end-user-report-grid {
			height: auto !important;
			min-height: unset !important;
			
		}
		.default-kendo-grid.weblink-report-grid {
			min-height: 5rem !important;
			height: auto !important;
		}
		.k-pager{
			margin-top: 0.875rem !important;
		}
		.product-order-details-container{
			min-height: calc(100vh - 10.75rem) !important;
			padding-bottom: 6.5rem;
			height: auto !important;
		}
		.page-content-body {
			min-height: calc(100vh - 9.35rem);
			height: auto !important;
		}
		.default-kendo-grid.audit-report-grid {
			height: 100% !important;
			max-height: 80rem;	
		}
	}
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	html {
		font-size: 13px;
	}
	.w-100 {
		&.w-md-auto {
			width: auto !important;
		}
	}
	.d-md-none {
		display: none !important;
	}
	.justify-content-md-end {
		justify-content: flex-end !important;
	}
	.ms-md-auto {
		margin-bottom: auto !important;
	}

	.settings-page
		.page-content-body
		.settings-box-container
		.settings-layout-container
		.left-column:after {
		display: none;
	}
	.addGroup-box-container {
		padding: 1.25rem 0.625rem 1.25rem 0.625rem;
		.default-form-style {
			width: 100%;
			.row-gutter-10px {
				.form-group {
					width: calc(100% - 2.5rem);
					&.product-description-group {
						margin-left: 2.5rem;
					}
				}
			}
		}
		.product-group-list-box .list-box-inner {
			.two-column .k-listbox {
				.k-list-scroller .k-list-content .k-list-ul .k-list-item {
					width: calc(100% - 1rem);
				}
			}
			.col-right {
				margin-top: 0;
				height: 100%;
			}
		}
	}
	.create-l3-box-container {
		.connection-method-row {
			margin-bottom: 1.7rem;
		}
	}

	.inline-list-card li {
		.date-box {
			min-width: unset;
		}
		.location-box {
			min-width: 10rem;
		}
	}
	.inline-list-card.box-card-type-3 li {
		max-width: calc(33.333333% - 1.15rem);
	}
	.weblink-box-container {
		form {
			height: 100%;
			.weblink-row {
				height: calc(100% - 5rem);
				.weblink-left-box {
					height: auto;
					margin-bottom: 1rem;
				}
				.weblink-right-box {
					height: calc(100% - 7rem);
				}
			}
		}
	}

	.header-popover {
		max-height: 35rem;
		max-width: 30.4375rem;
		.k-popover-callout.k-callout-n {
			left: 83%;
		}
	}
	.tenant-search.notification-search-form {
		max-width: 35rem;
	}
	.page-wrapper .k-drawer-container .k-drawer-content .page-content .page-header.with-right-form {
		flex-wrap: wrap;
		// flex-direction: column;
		// align-items: flex-start;
		flex-direction: row;
		align-items: center;
		.default-form-style {
			max-width: 100%;
			flex-wrap: wrap;
			.form-group {
				justify-content: flex-end;
			}
			.form-control {
				max-width: 12rem;
				width: 100%;
			}
			.kWrapper {
				max-width: 12rem;
			}
		}
	}

	.pagination-content-body .pagination-box-container.overflow-y-visible {
		height: calc(100% - 2rem);
	}
	.po-retrieval-group {
		.form-control-radio-btn {
			max-width: calc(33.33% - 0.75rem);
		}
	}
	.scanned-products-wrapper .carousel {
		min-height: 20rem;
	}
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	html {
		font-size: 12px;
	}

	.language-switcher-wrapper {
		.k-dropdownlist {
			padding: 0.5rem 0.5rem 0.5rem 1rem;
		}
		select {
			background-position: calc(100% - 0.65rem) 50%;
			padding: 0.5rem 1.5625rem 0.5rem 1rem;
		}
	}

	.page-header {
		flex-wrap: wrap;
		.tenant-search {
			justify-content: flex-end;
			margin-left: auto;

			.form-control {
				margin-right: 0.5rem;
			}
		}
	}
	.btn.icon-add-btn svg {
		margin-right: 0.5rem;
	}
	.tenant-content-body {
		height: calc(100% - 10.6rem);
	}
	.no-data {
		.no-data-body {
			max-width: 30.875rem;
			max-height: 30.875rem;

			.no-data-inner {
				max-width: 30.875rem;
				max-height: 30.875rem;

				img {
					max-width: 10rem;
				}

				h1 {
					font-size: var(--fz-23px);
				}
			}

			&::after {
				max-width: 30.875rem;
				max-height: 30.875rem;
			}
		}
	}
	.dashboard-body {
		.dashboard-container {
			.dsb-left-box1 {
				.dsb-summary-wrapper {
					.dsb-summary-left {
						width: calc(100% - 14.5rem);
					}
					.dsb-summary-right {
						width: 13rem;
					}
				}
			}

			.typewriter {
				font-size: var(--fz-24px);
				width: 20rem;
			}
			@keyframes text {
				0% {
					width: 0;
				}
				100% {
					width: 20rem;
				}
			}
		}
		.dsb-right {
			flex-direction: column !important;
			.dashboard-container {
				width: 100% !important;
			}
		}
	}
	.addGroup-box-container {
		.addGroup-box-container-inner {
			overflow-y: auto;
			padding-right: 0.75rem;
		}
		.product-group-list-box {
			height: 25rem;
			.list-box-inner {
				.two-column {
					width: 100%;
					height: calc(50% - 3rem);
					min-height: 25rem;
				}
			}
		}
	}
	.addGroup-box-container .product-group-list-box .error-msg {
		position: fixed;
		bottom: 1.5rem;
		left: 1.5rem;
	}
	.resp-tab {
		display: none !important;
	}

	.create-l3-box-container {
		.connection-method-row {
			margin-bottom: 0;
			margin-top: 0;
		}
	}
	.default-kendo-grid
		.k-grid-header
		.k-grid-header-wrap
		.k-table
		tr
		th
		.sort-wrapper
		.k-grid-header-menu {
		display: none;
	}
	.inline-list-card li {
		align-items: flex-start;
		justify-content: space-between;
		flex-direction: column;
		.right-box {
			margin-top: 1rem;
			flex-wrap: wrap;
		}
	}

	.inline-list-card.box-card-type-3 li {
		max-width: calc(50% - 0.85rem);
		.right-box {
			margin-top: 0rem;
			flex-wrap: wrap;
		}
	}
	.page-wrapper
		.k-drawer-container
		.k-drawer-content
		.page-content
		.page-header.with-right-form
		.default-form-style
		.form-group {
		gap: 0.5rem;
	}
	.add-product-row {
		.left-column {
			position: fixed;
			top: 9rem;
			left: 1rem;
			width: 18.5rem;
			height: calc(100% - 10rem);
			padding: 1rem 0.5rem 1rem 1rem;
			border-radius: 0.625rem;
			background-color: rgba(var(--info-color-rgb), 0.1);
			.stepper-navigation {
				height: 100%;
				overflow-y: auto;
				padding-right: 0.5rem;
			}
		}
		.right-column {
			position: fixed;
			top: 9rem;
			left: 19.5rem;
			height: calc(100% - 10rem) !important;
			width: calc(100% - 19.5rem);
			overflow-y: auto;
			.product-form-wrapper {
				padding-left: 1.25rem;
			}
		}
	}
	.k-daterangepicker-popup {
		.k-calendar-view {
			min-height: unset;
		}
		.k-calendar {
			font-size: var(--fz-14px);
			.k-content {
				margin: 0;
			}
			.k-calendar-header {
				.k-button {
					font-size: var(--fz-14px);
				}
			}
		}
		.k-month-calendar .k-calendar-td,
		.k-calendar-monthview .k-calendar-td {
			width: 1.375rem;
			height: 1.375rem;
			inline-size: var(--INTERNAL--kendo-calendar-cell-size, 1.375rem);
			block-size: var(--INTERNAL--kendo-calendar-cell-size, 1.375rem);
		}
	}
	.header-popover {
		max-height: 23rem;
		max-width: 30.4375rem;
	}
	.notification-list-card-wrapper {
		.inline-list-card {
			.notifictaion-item {
				align-items: flex-start;
				flex-direction: column;
				.list-title {
					max-width: 100%;
				}
				.right-box {
					max-width: 100%;
				}
			}
		}
	}
	.page-wrapper .k-drawer-container .k-drawer-content .page-content .page-header {
		&.with-right-form {
			flex-direction: row;
			align-items: center;
			.default-form-style {
				flex-wrap: wrap;
				max-width: 100%;
				.form-group {
					flex-wrap: wrap;
					justify-content: flex-end;
				}

				.form-control {
					max-width: 12rem;
				}
			}
		}
		.btn.icon-add-btn {
			padding: 0.375rem 1rem !important;
			min-width: 10rem;
		}
	}
	.pagination-content-body .pagination-box-container.overflow-y-visible {
		height: calc(100% - 3.5rem);
	}
	.default-date-ranger-combo-box {
		max-width: 15rem;
		min-width: 15rem;
		.k-daterangepicker {
			gap: 4px;
			&:before {
				left: 0.5rem;
			}
			&:after {
				right: 0.5rem;
			}
			.k-floating-label-container {
				&:first-child .k-input-inner {
					padding-left: 2.125rem !important;
				}
				&:last-child .k-input-inner {
					padding-right: 1.8125rem !important;
				}
			}
		}
	}
	.po-retrieval-group {
		flex-direction: row;
		height: 100%;
		.form-control-radio-btn {
			max-width: calc(50% - 0.75rem);
			min-width: unset;
			width: 100%;
		}
	}

	.serial-number-list-container {
		.k-listview-content {
			.k-listview-item{
				width: 100%;
			}
		}
	}
	
	
	.sc-container.end-user-container{
		.first-sc-container {
			max-height: 100%;
			.scanned-products-wrapper {
				height: 100%;
				.carousel {
					min-height: unset;
					margin: auto;
				}
			}
		}
	}
	
	.product-order-details-container {
		form {
			&.weblink-report-form{
				.weblink-report-grid.end-user-report-grid{
					height: calc(100vh - 40rem) !important;
					min-height: 24rem;
				}
			}
		}
	}
	.product-order-details-container form.weblink-report-form .weblink-report-grid {
		height: calc(100vh - 32rem) !important;
		min-height: 24rem;
	}
}

@media (max-width: 575.98px) {
	.page-wrapper .k-drawer-container .k-drawer-content .page-content .page-header {
		&.with-right-form {
			flex-direction: column;
			align-items: flex-start;
		}
	}
	.page-wrapper .sidebar-toggle-btn {
		left: 5.25rem;
	}
	.main-header {
		.k-appbar-section {
			margin-left: 0.5rem;

			&.user-profile-circle {
				margin-left: 1.25rem;
			}
			&.file-failed-circle {
				margin-right: 0.5rem;
				.file-failed-btn {
					width: 2.25rem;
					min-width: 2.25rem;
					label {
						display: none;
					}
					svg {
						margin-right: 0;
						margin-left: 0.125rem;
					}
				}
			}
		}
	}
	.right-end-image {
		height: 2.5rem;
		width: auto;
		max-width: 2.5rem;
	}

	.k-animation-container .custom-dropdown.profile-dropdown {
		right: 2.5rem;
	}

	.k-animation-container .custom-dropdown.profile-dropdown .k-menu-group:after,
	.k-animation-container .custom-dropdown.profile-dropdown .k-menu-group:before {
		right: 1.65rem;
	}
	.switch-toggle-column {
		margin-bottom: 1rem;
	}
	.language-switcher-wrapper {
		max-width: 5.5rem;
		margin-top: 0.1rem;
		left: 7rem;
		.k-dropdownlist {
			padding: 0.5rem 0rem 0.5rem 0.5rem;
			.k-input-inner {
				padding: 0 !important;
				min-width: 3.25rem;
				max-width: 3.5rem;
			}
			.k-icon-button {
				width: 1rem;
			}
		}
	}
	.btn.icon-add-btn {
		width: auto;
	}
	.no-data {
		.no-data-body {
			max-width: 20.875rem;
			max-height: 20.875rem;

			.no-data-inner {
				max-width: 20.875rem;
				max-height: 20.875rem;

				img {
					max-width: 10rem;
				}

				h1 {
					font-size: var(--fz-20px);
				}
			}

			&::after {
				display: none;
			}
		}
	}
	.alert-modal {
		justify-content: flex-end !important;
		.k-dialog {
			max-height: 24rem !important;
			max-width: 100% !important;
			border-radius: unset !important;
		}
	}
	.default-offcanvas.width-452px {
		max-width: 26rem;
		width: 100% !important;
	}
	.k-datepicker-popup {
		.k-calendar {
			.k-calendar-navigation {
				width: 3.5rem !important;
				.k-calendar-content ul {
					width: 3.5rem;
				}
			}
		}
	}
	.default-offcanvas {
		.canvas-title {
			padding: 1.4375rem 1.5rem 1.25rem 1.5rem;
		}
	}
	.default-offcanvas .offcanvas-body .default-form-style,
	.default-offcanvas form .default-form-style {
		padding: 0 0.25rem 1.25rem 1.5rem;
	}
	.default-offcanvas .offcanvas-body .default-offcanvas-footer,
	.default-offcanvas form .default-offcanvas-footer {
		padding: 0 1.5rem 0 1.5rem;
	}
	.add-product-group-module .page-content-body {
		height: calc(100% - 7.4375rem);
		&.weblink-report-body{
			height: calc(100% - 3.4375rem);
		}
	}
	.addGroup-box-container {
		.addGroup-box-container-inner {
			overflow-y: auto;
			padding-right: 0.75rem;
		}
		.default-form-style {
			.row-gutter-10px .form-group {
				width: 100%;
				&.product-description-group {
					margin-left: 0rem;
				}
			}
		}
		.product-group-list-box {
			height: auto;
			.list-box-inner {
				flex-direction: column;
				.two-column {
					width: 100%;
					height: 20rem !important;
					min-height: 20rem;
					&.col-right {
						margin-left: 0;
						margin-top: 6rem !important;
					}
					&.col-left .k-listbox .k-listbox-actions {
						position: absolute;
						left: 0;
						bottom: -5.65rem;
						top: unset;
						height: auto;
						width: 100%;
						justify-content: center;
						align-items: center;
						gap: 0.25rem;
						flex-direction: column-reverse;
						.k-button {
							width: 2.5rem;
							height: 2.5rem;
							.k-i-caret-alt-right::before {
								content: '\e006' !important;
							}
							.k-i-caret-alt-left::before {
								content: '\e004' !important;
							}
						}
					}
				}
			}
			&.style-type-2 {
				.list-box-inner {
					.two-column.col-right {
						margin-top: 6rem !important;
					}
				}
			}
		}
	}
	.page-header {
		.icon-add-btn {
			margin-left: auto;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}
	.manufacturer-header {
		.icon-add-btn {
			margin-left: auto;
			margin-top: 0.5rem;
		}
	}
	.user-module .page-content-body .userlist-box-container {
		padding: 1.1875rem 1.25rem 1.1875rem 1.875rem;
		height: calc(100% - 5rem);
	}
	.manufacturer-box-container {
		height: calc(100% - 5rem) !important;
	}
	.add-product-group-module {
		.btn.icon-add-btn {
			min-width: 7rem;
		}
	}
	.add-product-module {
		.product-form-wrapper {
			position: relative;
			z-index: 1;
		}
		.stepper-navigation {
			.k-animation-container.k-animation-container-relative {
				position: absolute;
				overflow: visible;
				box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2) !important;
				background-color: var(--white-color) !important;
				.k-child-animation-container {
					padding: 0 !important;
				}
			}
			.k-panelbar {
				.k-panelbar-item {
					.k-link {
						padding: 0 1rem 0 0 !important;
						.panel-link-btn {
							margin-bottom: 0 !important;
							padding: 0.75rem 1.25rem 0.75rem 3.25rem !important;
							white-space: nowrap;
							&.completed {
								&::after {
									top: 0.9rem !important;
								}
							}
						}
					}
				}
			}
			.panel-link-btn {
				padding: 0.75rem 1.25rem 0.75rem 3.25rem !important;
				white-space: nowrap;
				&.completed {
					&::after {
						top: 0.95rem !important;
					}
				}
			}
		}
		.page-content-body .row {
			.product-form-wrapper {
				height: calc(100% - 15.5rem) !important;
				.form-body {
					padding-right: 0.5rem !important;
				}
			}
		}
	}
	.add-product-module .page-content-body .row .product-form-wrapper .default-form-style {
		.form-body .bottom-btn-wrapper .btn {
			min-width: unset;
			margin-left: 0.625rem;
		}
		.bottom-btn-wrapper .btn {
			min-width: 6rem;
			margin-left: 1.25rem;
		}
	}

	.inline-list-card.box-card-type-3 li {
		max-width: calc(100% - 0.5rem);
	}
	.awaiting-actions-container {
		height: calc(100% - 2rem) !important;
	}
	.dashboard-container {
		.widget-header {
			flex-wrap: wrap;
		}
		.dsb-summary-wrapper {
			flex-direction: column;
			.dsb-summary-left {
				width: 100% !important;
				height: 20rem;
			}
			.dsb-summary-right {
				width: 100% !important;
			}
		}

		.top-product-header {
			flex-wrap: wrap;
		}
		.top-product-mobile {
			align-items: flex-start !important;
			flex-direction: column !important;
			.default-form-style {
				width: 100%;
				flex-wrap: wrap;
				align-items: flex-start !important;
				gap: 0.75rem !important;
				margin-top: 0.25rem;

				.form-group {
					width: 100%;
					max-width: 15rem;
				}
			}
		}
	}
	.add-product-row {
		.left-column {
			position: fixed;
			top: 9rem;
			left: 1rem;
			width: calc(100% - 2rem);
			height: auto;
			padding: 0 !important;
			border-radius: 0;
			background-color: transparent;
			.stepper-navigation {
				height: auto;
				overflow-y: visible;
				padding-right: 0;
			}
		}
		.right-column {
			position: fixed;
			top: 23.5rem;
			left: 1rem;
			padding: 0;
			height: calc(100% - 10rem) !important;
			width: calc(100% - 2rem);
			overflow-y: auto;
			.product-form-wrapper {
				padding-left: 1.25rem;
			}
		}
	}
	.k-daterangepicker-popup {
		.k-calendar-view {
			flex-direction: column;
			gap: 0rem;
		}
	}
	.editable-tooltip-wrapper {
		max-width: unset;
		width: unset;
		.tooltip-inner {
			width: 100%;
			max-width: 17rem !important;
			min-width: 17rem !important;
			position: absolute;
			left: -3rem !important;
		}
	}
	.weblink-box-container {
		form {
			height: 100%;
			.weblink-row {
				.weblink-wrapper {
					.link-head {
						min-width: 7rem;
						max-width: 7rem;
						width: 100%;
					}
				}
			}
		}
	}
	.addGroup-box-container .product-group-list-box .error-msg {
		position: absolute;
		bottom: unset;
		left: 0rem;
		top: 22.25rem;
		font-weight: 400;
	}
	.header-popover {
		max-height: 30rem;
		max-width: 19.5rem;
		margin-left: -4rem;
		.k-popover-callout.k-callout-n {
			left: 70%;
		}
	}
	.pagination-content-body .pagination-box-container.overflow-y-visible {
		height: calc(100% - 5rem);
	}
	.page-wrapper
		.k-drawer-container
		.k-drawer-content
		.page-content
		.page-header.with-right-form
		.default-form-style {
		.form-control {
			max-width: 9rem;
		}
		.kWrapper {
			max-width: 15rem;
			margin-right: 0.75rem;
			.form-control {
				width: 100%;
				max-width: 100%;
			}
		}
	}
	.po-retrieval-group {
		.form-control-radio-btn {
			max-width: 100%;
		}
	}
	/*---------------Scanner Products desktop design Starts here---------------*/
	.sc-container {
		flex-direction: column;
		.first-sc-container {
			width: 100%;
			max-height: 23rem;
			background: linear-gradient(
				180deg,
				rgba(217, 217, 217, 0.9) 41.78%,
				rgba(217, 217, 217, 0.4) 100%
			);
		}
	}

	.logo-container.scanned-products {
		width: 100%;
		max-width: 62.875rem;
		max-height: 31.875rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		img {
			max-width: 3rem;
			padding: 1.125rem 0 0 1.125rem;
		}
		span {
			padding: 1.125rem 1.125rem 0 0rem;
			font-size: var(--fz-10px);
			color: var(--grid-pg-btn-color);
		}
	}
	.scanned-products-wrapper {
		background: transparent !important;
		justify-content: center;
		flex-direction: column;
		padding: 6rem 1.25rem 1.25rem;
		display: flex;
		height: 22.25rem;
		.carousel {
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column-reverse;
			justify-content: center;
			align-items: center;
			min-height: auto;
			background: transparent !important;

			.carousel-inner {
				height: 100%;
				.carousel-caption {
					height: 100%;
					width: 100%;
					text-align: center !important;
					padding: 1rem 0 !important;
					img {
						width: auto !important;
						height: 100% !important;
					}
				}
			}

			.carousel-indicators {
				position: relative;
				margin-left: 0;
				justify-content: center;
				margin-right: 0;

				[data-bs-target] {
					width: 1.25rem;
					height: 0.25rem;
					background-color: #e7643b;
				}
			}

			.carousel-control-prev,
			.carousel-control-next {
				display: none;
			}
		}
	}
	.scanner-products-right-wrapper {
		width: 100%;
		.custom-scroller {
			height: calc(100% - 21rem);
		}
		.scan-wrap {
			display: inline-flex;
			.scan-lbl {
				font-weight: 400;
				font-size: var(--fz-12px);
				line-height: 1.125rem;
				display: flex;
				align-items: center;
				margin-bottom: 0;
			}
			.scan-score {
				width: 1.563rem;
				height: 1.563rem;
				border-radius: 0.25rem;
				color: var(--theme-text-color-primary);
				display: inline-flex;
				align-items: center;
				justify-content: center;
				font-size: var(--fz-10px);
				font-weight: 600;
				line-height: 100%;
			}
		}
	}
	.dark-theme {
		.sc-container .first-sc-container {
			background: linear-gradient(
				180deg,
				rgba(217, 217, 217, 0) 41.78%,
				rgba(217, 217, 217, 0.114) 100%
			) !important;
		}
	}

	.scan-img-width {
		width: 100% !important;
		max-width: 100% !important;
	}
	.sc-container.end-user-container {
		.first-sc-container {
			max-height: 40%;
		}
		.scanner-products-right-wrapper{
			height: 60%;
			padding-bottom: 2rem;
			.custom-scroller {
				height: 100%;
			}
		}
	}
	/*---------------Scanner Products Mobile design Ends here---------------*/
	.sc-container.end-user-container.static-weblink-page{
		background-color: var(--white-color);
		height: 100%;
    	overflow-y: auto;
		&.with-footer{
			height: calc(100% - 3rem);
		}
	}
	.sc-container.end-user-container.static-weblink-page .inline-img-wrapper .image-box .watermark{
		font-size: var(--fz-28px);
	}
	.sc-container.end-user-container.static-weblink-page {
		.first-sc-container {
			max-height: unset !important;
		}
		.scanner-products-right-wrapper{
			height: auto !important;
			.custom-scroller {
				overflow-y: visible;
				padding-right: 0rem;
			}
		}
	}

	.product-order-details-container form.weblink-report-form .weblink-report-grid {
		height: calc(100vh - 40rem) !important;
	}
	.product-order-details-container {
		form {
			&.weblink-report-form{
				.weblink-report-grid.end-user-report-grid{
					height: calc(100vh - 59rem) !important;
					
				}
			}
		}
	}
	.question-modal .default-form-style .form-control.text-area {
		height: 11rem;
		resize: none;
	}
	.alert-modal.cropper .k-dialog .k-actions .k-button {
		max-width: unset;
	}
	
	.grid-custom-container .grid-custom-container-inner.with-toolbar .k-toolbar{
		.refresh-btn{
			width: 2.65rem !important;
			min-width: unset !important;
			font-size: 0 !important;
			margin-right: 1rem;
			svg{
				font-size: 1rem;
			}
		}
	}
}
@media screen and (max-width: 995px), 
       screen and (max-height: 700px) {
		// .weblink-management-container {
		// 	.k-grid-content.k-virtual-content {
		// 		height: calc(100vh - 34rem) !important;
		// 	}
		// }
}
